import React from 'react';
import Main from '../containers/Layout';
import orderInvoiceGenerationContent from '../../content/pages/order-and-invoice-generation.yml';
import OrderInvoiceGenerationContainer from '@/containers/toolkit/OrderInvoiceGenerationContainer';

export interface OrderInvoiceGeneration {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    description: string;
    tickedDescription: { item: string }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    cards: {
      title: string;
      greyText: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };
  viewport3: {
    title: string;
    subTitle: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport4: {
    cards: {
      title: string;
      greyText: string;
      tickedDescription?: { item: string }[];
      description1?: string;
      description2?: string;
      image: string;
    }[];
  };
  viewport5: {
    title: string;
    leftColumn: { description1: string }[];
    rightColumn: { description1: string }[];
    bottomText: string;
    button: { label: string; url: string };
  };
}

const OrderAggregationAndManagement: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4, viewport5 } =
    orderInvoiceGenerationContent as unknown as OrderInvoiceGeneration;
  return (
    <Main>
      <OrderInvoiceGenerationContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
        }}
      />
    </Main>
  );
};

export default OrderAggregationAndManagement;
